import {
  campaignsApi,
  useImportInternetTargetingTemplateMutation,
  useImportTvTargetingTemplateMutation,
  useUpdateCampaignMutation
} from '../../../../api/rtkQueryApi/platform/campaignsApi'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { RootState } from '../../../../app/rootReducer'
import {
  BbMinTotalBudget,
  BbMinWeeklyBudget,
  BudgetMethod,
  CampaignBudget, CampaignBudgetSetter, InternetMinTotalBudget,
  InternetMinWeeklyBudget, TvMinTotalBudget,
  TvMinWeeklyBudget
} from '../../CampaignBudgetSetter'
import { useWhiteLabel } from '../../../../whiteLabel/WhiteLabelContext'
import moment from 'moment'
import { fetchCampaign, setCampaign } from '../campaignSlice'
import { CampaignActiveStatus, CampaignStatus, CampaignType } from '../../../../api/interfaces'
import { showSuccessToast } from '../../../../app/appSlice'
import {
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiLoadingContent,
  EuiSpacer, EuiSwitch, EuiText
} from '@elastic/eui'
import UnpaidCallOut from '../UnpaidCallOut'
import BudgetChangeModal from '../../../../components/Modals/BudgetChangeModal'
import { useParams } from 'react-router-dom'
import { EditCampaignDeliveryStrategy, ICampaignDelivery } from './forms/EditCampaignDeliveryStrategy'
import {
  GetInternetTargetingTemplateFromCampaign,
  GetTargetingFromCampaign, GetTvTargetingTemplateFromCampaign
} from '../../../../utils/TransformTargeting'
import { BaseTargeting } from '../../../../api/interfaces/TargetingTemplate'

export const EditCampaignDetails: React.FC = () => {
  let { campaignId } = useParams<{ campaignId: string }>()
  const {
    currentAccount,
    isStaffUser
  } = useSelector((state: RootState) => state.app)
  const { campaign } = useSelector((state: RootState) => state.campaign)
  const [updateCampaign] = useUpdateCampaignMutation()
  const dispatch = useDispatch()
  const [amount, setAmount] = useState<number>(0)
  const [isValid, setIsValid] = useState<boolean>(true)

  const [name, setName] = useState<string>('')
  const [maxCpm, setMaxCpm] = useState<number>(0)

  const [isBudgetChangeModalVisible, setIsBudgetChangeModalVisible] = useState(false)
  const [campaignBudget, setCampaignBudget] = useState<CampaignBudget>({
    budget: 0,
    method: BudgetMethod.Indefinite
  })
  const whiteLabel = useWhiteLabel()
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false)
  const [campaignDelivery, setCampaignDelivery] = useState<ICampaignDelivery>()

  const [importInternetTargetingMutation] = useImportInternetTargetingTemplateMutation()
  const [importTvTargetingMutation] = useImportTvTargetingTemplateMutation()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (campaign) {

      if(campaign.type === CampaignType.Internet) {
        const targeting = campaign && GetInternetTargetingTemplateFromCampaign(campaign)
        const inventorySettings = targeting?.advancedInternetTargeting?.inventorySettings
        const deviceSettings = targeting?.advancedInternetTargeting?.deviceSettings

        setCampaignDelivery({
          baseTargeting: GetTargetingFromCampaign(campaign) as BaseTargeting,
          inventorySettings: inventorySettings,
          deviceSettings: deviceSettings
        })
      }
      else {
        setCampaignDelivery({
          baseTargeting: GetTargetingFromCampaign(campaign) as BaseTargeting,
          inventorySettings: undefined,
          deviceSettings: undefined
        })
      }
    }
  }, [campaign])



  useEffect(() => {
    if (campaign) {
      setCampaignBudget({
        budget: campaign.budget,
        start: campaign.startDate ? moment.utc(campaign.startDate) : undefined,
        end: campaign.endDate ? moment.utc(campaign.endDate) : undefined,
        method: whiteLabel?.isAgencies() && campaign.startDate ? BudgetMethod.Dates : BudgetMethod.Indefinite
      })
      setName(campaign.name)
      setMaxCpm(campaign.maxCpm ?? 0)
    }
  }, [campaign, whiteLabel])

  useEffect(() => {
    setIsValid(isValidBudget(campaignBudget))
  }, [campaignBudget])

  const onBudgetChangeModalComplete = (shouldFetchCampaign: boolean) => {
    if (shouldFetchCampaign) {
      dispatch(fetchCampaign(currentAccount!.id, campaignId))
      dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaign', id: campaignId }]))
    }
    setIsBudgetChangeModalVisible(false)
  }

  const onBudgetChangeModalCancel = () => {
    setIsBudgetChangeModalVisible(false)
  }

  const showBudgetChangeModal = (amount: number) => {
    if (campaign) {
      setAmount(amount)
      setIsBudgetChangeModalVisible(true)
    }
  }

  const onSave = async () => {
    if (!campaign) {
      return
    }
    const amount = campaignBudget.budget - campaign.budget
    if (amount > 0 && !campaign.canRemove && campaign.activeStatus === CampaignActiveStatus.Active) {
      showBudgetChangeModal(amount)
    } else {
      setIsSaving(true)
      const updatedCampaign = await updateCampaign({
        accountId: currentAccount!.id,
        campaignId: campaign.id,
        name: name,
        budget: campaignBudget.budget,
        maxCpm,
        startDate: campaignBudget.start,
        endDate: campaignBudget.end
      }).unwrap()

      if(updatedCampaign.type === CampaignType.Internet) {
        const baseInternet = GetInternetTargetingTemplateFromCampaign(updatedCampaign)
        const camp = await importInternetTargetingMutation({
          campaignId: updatedCampaign.id,
          targeting: {
            ...baseInternet,
            ...campaignDelivery?.baseTargeting,
            advancedInternetTargeting: {
              inventorySettings: campaignDelivery?.inventorySettings,
              deviceSettings: campaignDelivery?.deviceSettings
            }
          },
          accountId: updatedCampaign.accountId
        }).unwrap()

        dispatch(setCampaign(camp))
      }

      if(updatedCampaign.type === CampaignType.TV) {
        const baseTv = GetTvTargetingTemplateFromCampaign(updatedCampaign)
        const camp = await importTvTargetingMutation({
          campaignId: updatedCampaign.id,
          targeting: {
            ...baseTv,
            ...campaignDelivery?.baseTargeting
          },
          accountId: updatedCampaign.accountId
        }).unwrap()

        dispatch(setCampaign(camp))
      }

      setIsSaving(false)
      dispatch(showSuccessToast('Saved Campaign'))
      dispatch(fetchCampaign(currentAccount!.id, campaignId))
    }
  }

  const getMinWeeklyBudget = () => {
    if (!campaign) {
      return TvMinWeeklyBudget
    }
    if (campaign.type === CampaignType.Internet) {
      return InternetMinWeeklyBudget
    } else if (campaign.type === CampaignType.TV) {
      return TvMinWeeklyBudget
    } else if (campaign.type === CampaignType.Billboard) {
      return BbMinWeeklyBudget
    }
    return TvMinWeeklyBudget
  }

  const getMinTotalBudget = () => {
    if (!campaign) {
      return TvMinTotalBudget
    }

    if (campaign.type === CampaignType.Internet) {
      return InternetMinTotalBudget
    } else if (campaign.type === CampaignType.TV) {
      return TvMinTotalBudget
    } else if (campaign.type === CampaignType.Billboard) {
      return BbMinTotalBudget
    }
    return TvMinTotalBudget
  }

  const getMinStartDate = () => {
    if (!campaign?.activated || !campaign.startDate) {
      return isStaffUser ? moment() : moment().add(3, 'day')
    }

    const startDate = moment(campaign.startDate)
    if (startDate < moment()) {
      return startDate
    }

    return moment().add(3, 'day') < startDate ? moment().add(3, 'day') : startDate
  }

  const isValidBudget = (budget: CampaignBudget): boolean => {
    if (!campaign) {
      return false
    }

    if (budget.method === BudgetMethod.Indefinite) {
      return budget.budget >= getMinWeeklyBudget() && !budget.start && !budget.end
    } else if (budget.method === BudgetMethod.Dates) {
      return !!(budget.budget >= getMinTotalBudget() && budget.start && budget.end && moment(budget.start).isBefore(budget.end) && (moment(budget.start).isSameOrAfter(moment(campaign.startDate), 'day') || moment(budget.start).endOf('day').isAfter(getMinStartDate().startOf('day'))))
    }

    return false
  }

  const onBudgetChange = (budget: CampaignBudget) => {
    setCampaignBudget(budget)
  }

  if (!campaign) {
    return <EuiLoadingContent lines={10} />
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {campaign?.status === CampaignStatus.PaymentFailed && !campaign.isUnpaidSubscriptionPayment && (
        <React.Fragment>
          <UnpaidCallOut campaignId={campaign.id} hasBundle={campaign.hasBundle} />
          <EuiSpacer />
        </React.Fragment>
      )}
      <EuiFormRow label={
        <EuiText size="xs" color={'primary'}>
          <strong>Campaign Name</strong>
        </EuiText>
      }>
        <EuiFieldText name="name" value={name} onChange={e => setName(e.target.value)} placeholder="" fullWidth />
      </EuiFormRow>
      <CampaignBudgetSetter campaign={campaign} campaignBudget={campaignBudget}
                            onBudgetChange={onBudgetChange} />
      {(campaign.type === CampaignType.Internet || campaign.type === CampaignType.TV) && (
        <>
          <EuiFormRow>
            <EuiSwitch label={'Show Advanced Options'} checked={showAdvancedSettings}
                       onChange={() => setShowAdvancedSettings(!showAdvancedSettings)} />
          </EuiFormRow>
          <EuiSpacer />
          {
            showAdvancedSettings && campaignDelivery && (
              <EditCampaignDeliveryStrategy campaignDelivery={campaignDelivery} onCampaignDeliveryChanged={setCampaignDelivery} campaignType={campaign.type}/>
            )
          }
        </>
      )}
      <EuiFormRow>
        <EuiButton id="save" onClick={onSave} disabled={!isValid} isLoading={isSaving}>
          Save
        </EuiButton>
      </EuiFormRow>
      <BudgetChangeModal campaign={campaign} isBudgetChangeModalVisible={isBudgetChangeModalVisible} name={name}
                         budget={campaignBudget.budget} differenceAmount={amount}
                         onConfirmComplete={onBudgetChangeModalComplete} onCancel={onBudgetChangeModalCancel} />
    </React.Fragment>
  )
}