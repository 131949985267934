import { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFormRow } from '@elastic/eui'
import { InternetLocationTargeting } from '../../../../../api/interfaces/TargetingTemplate'
import { RootState } from '../../../../../app/rootReducer'
import { GetInternetTargetingTemplateFromCampaign } from '../../../../../utils/TransformTargeting'
import { useUpdateCampaignTargeting } from './useUpdateCampaignTargeting'
import { LocationTargetingForm } from '../../../../../components/targeting/TargetingForm/LocationTargetingForm'

export const EditInternetLocationTargeting = () => {
  const { campaign } = useSelector((state: RootState) => state.campaign)
  const targeting = campaign && GetInternetTargetingTemplateFromCampaign(campaign)
  const [locationTargeting, setLocationTargeting] = useState(targeting?.locationTargeting as InternetLocationTargeting)
  const { updateCampaignTargeting, importInternetTargetingMutationResult } = useUpdateCampaignTargeting()

  const onSave = async () => {
    if (!campaign) {
      return
    }
    await updateCampaignTargeting(campaign, {
      ...targeting,
      locationTargeting: locationTargeting
    })
  }

  return (
    <>
      <EuiFormRow label={'Choose Location Targeting Strategy'} fullWidth>
        <LocationTargetingForm locationTargeting={locationTargeting} onLocationTargetingChange={setLocationTargeting} />
      </EuiFormRow>
      <EuiButton onClick={onSave} isLoading={importInternetTargetingMutationResult.isLoading}>
        Save
      </EuiButton>
    </>
  )
}
