import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../../app/rootReducer'
import { getSignalRConnection } from '../GetSignalRConnection'

let plannerConnection: HubConnection

export type PlannerHubMethods = {
  GetPlanStatus: { clientPlanId: string }
}

type UsePlannerConnectionProps = {
  onNotifyClientPlanStatus?: ({ status }: { status: null }) => void
  onSendTextMessage?: ({ message }: { message: string }) => void
}

export const usePlannerConnection = ({ onNotifyClientPlanStatus, onSendTextMessage }: UsePlannerConnectionProps) => {
  const { accessToken } = useSelector((state: RootState) => state.app)
  const [connected, setConnected] = useState(plannerConnection?.state === HubConnectionState.Connected)

  useEffect(() => {
    if (!accessToken) {
      setConnected(false)
      return
    }
    if (!plannerConnection) {
      plannerConnection = getSignalRConnection({
        accessToken,
        connection: plannerConnection,
        hubPath: 'Planner',
        setConnected
      })
    }
  }, [accessToken])

  useEffect(() => {
    if (!plannerConnection || plannerConnection.state !== HubConnectionState.Connected) {
      return
    }
    onNotifyClientPlanStatus && plannerConnection.on('NotifyClientPlanStatus', onNotifyClientPlanStatus)
    onSendTextMessage && plannerConnection.on('SendTextMessage', onSendTextMessage)

    return () => {
      plannerConnection.off('NotifyClientPlanStatus')
      plannerConnection.off('SendTextMessage')
    }
  }, [plannerConnection])

  const invoke = async (method: keyof PlannerHubMethods, args: PlannerHubMethods[keyof PlannerHubMethods]) => {
    if (plannerConnection.state !== HubConnectionState.Connected) {
      return
    }
    await plannerConnection.invoke(method, args)
  }

  return { invoke, connected }
}
