import { useState } from 'react'

import { EuiFieldText, EuiForm, EuiFormRow, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui'

import { LocationTargetingType, TargetingType } from '../../../api/interfaces/CampaignTargeting'
import { AudienceTargeting, BaseTargeting, InternetTargeting, LocationTargeting, TvTargeting } from '../../../api/interfaces/TargetingTemplate'
import { AudienceTargetingForm } from './AudienceTargetingForm'
import { BillboardTargetingForm } from './BillboardTargetingForm'
import { InternetTargetingForm } from './InternetTargetingForm'
import { LocationTargetingForm } from './LocationTargetingForm'
import { TvTargetingForm } from './TvTargetingForm'

export type TargetingFields = {
  name: string
  accountId?: string
  locationTargeting: LocationTargeting
  audienceTargeting: AudienceTargeting
  tvTargeting: TvTargeting
  billboardTargeting: BaseTargeting
  internetTargeting: InternetTargeting
  targetingPriority: TargetingType
}

enum TargetingFormTab {
  Location = 'Location',
  Demographics = 'Demographics',
  Internet = 'Internet',
  TV = 'TV',
  Billboard = 'Billboard'
}

type TargetingFormProps = {
  onTargetingUpdated: (targetingFields: TargetingFields) => void
  targeting: TargetingFields
}

export const TargetingForm = ({ targeting, onTargetingUpdated }: TargetingFormProps) => {
  const [selectedTabId, setSelectedTabId] = useState<TargetingFormTab>(TargetingFormTab.Location)

  const onLocationTargetingChange = (locationTargeting: LocationTargeting) => {
    onTargetingUpdated({ ...targeting, locationTargeting })
  }
  const onAudienceTargetingChange = (audienceTargeting: AudienceTargeting) => {
    onTargetingUpdated({ ...targeting, audienceTargeting })
  }
  const onInternetTargetingChange = (internetTargeting: InternetTargeting) => {
    onTargetingUpdated({ ...targeting, internetTargeting })
  }

  const onTvTargetingChange = (tvTargeting: TvTargeting) => {
    onTargetingUpdated({ ...targeting, tvTargeting })
  }

  const onBillboardTargetingChange = (billboardTargeting: BaseTargeting) => {
    onTargetingUpdated({ ...targeting, billboardTargeting })
  }

  const tabs = [
    {
      id: TargetingFormTab.Location,
      name: 'Location'
    },
    {
      id: TargetingFormTab.Demographics,
      name: 'Demographics'
    },
    {
      id: TargetingFormTab.Internet,
      name: 'Internet'
    },
    {
      id: TargetingFormTab.TV,
      name: 'TV'
    },
    {
      id: TargetingFormTab.Billboard,
      name: 'Billboard'
    }
  ]

  const onSelectedTabChanged = (id: TargetingFormTab) => setSelectedTabId(id)

  const renderTabs = tabs.map((tab, index) => (
    <EuiTab onClick={() => onSelectedTabChanged(tab.id)} isSelected={tab.id === selectedTabId} key={index}>
      {tab.name}
    </EuiTab>
  ))

  return (
    <EuiForm component='form'>
      <EuiFormRow label='Name'>
        <EuiFieldText
          value={targeting.name}
          required
          onChange={e => {
            onTargetingUpdated({ ...targeting, name: e.target.value })
          }}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiTabs expand>{renderTabs}</EuiTabs>
      <EuiSpacer />
      {selectedTabId === TargetingFormTab.Location && <LocationTargetingForm locationTargeting={targeting.locationTargeting} onLocationTargetingChange={onLocationTargetingChange} forbiddenTargetingTypes={[LocationTargetingType.Map, LocationTargetingType.Zip]} />}
      {selectedTabId === TargetingFormTab.Demographics && <AudienceTargetingForm audienceTargeting={targeting.audienceTargeting} onAudienceTargetingChanged={onAudienceTargetingChange} />}
      {selectedTabId === TargetingFormTab.Internet && <InternetTargetingForm internetTargeting={targeting.internetTargeting} onInternetTargetingChanged={onInternetTargetingChange} />}
      {selectedTabId === TargetingFormTab.TV && <TvTargetingForm tvTargeting={targeting.tvTargeting} onTvTargetingChanged={onTvTargetingChange} />}
      {selectedTabId === TargetingFormTab.Billboard && <BillboardTargetingForm billboardTargeting={targeting.billboardTargeting} onBillboardTargetingChanged={onBillboardTargetingChange} />}
    </EuiForm>
  )
}
