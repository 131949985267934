import React from 'react'

import {
  EuiCallOut,
  EuiFormRow,
  EuiRadio,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import {
  City,
  Dma,
  GeoCircle, GeoPolygon, GeoRectangle,
  LocationTargeting,
  PostalCode
} from 'api/interfaces/TargetingTemplate'

import { AcTargeterSelection } from '../finders/AcTargeterSelection'
import { CityFinderXandr } from '../finders/CityFinderXandr'
import { DMAFinderXandr } from '../finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from '../finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from '../finders/RegionFinderXandr'
import TargetingMap from './TargetingMap'
import { LocationTargetingType } from '../../../api/interfaces/CampaignTargeting'

export interface ILocationTargetingForm {
  locationTargeting: LocationTargeting
  onLocationTargetingChange: (locationTargeting: LocationTargeting) => void
  forbiddenTargetingTypes?: LocationTargetingType[]
}

export const LocationTargetingForm: React.FC<ILocationTargetingForm> = ({
                                                                          locationTargeting,
                                                                          onLocationTargetingChange,
                                                                          forbiddenTargetingTypes
                                                                        }) => {
  const preventTargeting = forbiddenTargetingTypes ?? []

  return (
    <>
      {!preventTargeting.includes(LocationTargetingType.USA) && (
        <>
          <EuiRadio
            id="country"
            name="locationType"
            value="USA"
            label={
              <EuiText size="s">
                <strong>Target the USA</strong>
              </EuiText>
            }
            checked={locationTargeting.locationType === LocationTargetingType.USA}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.USA })
            }}
          />
          <EuiSpacer size="s" />
        </>
      )}
      {!preventTargeting.includes(LocationTargetingType.Region) && (
        <>
          <EuiRadio
            id="state"
            name="locationType"
            value="State"
            label={
              <EuiText size="s">
                <strong>Targeting by State</strong> (one or more U.S. States)
              </EuiText>
            }
            checked={locationTargeting.locationType === LocationTargetingType.Region}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Region })
            }}
          />
          <EuiSpacer size="s" />
        </>
      )}
      {!preventTargeting.includes(LocationTargetingType.DMA) && (
        <>
          <EuiRadio
            id="dma"
            name="locationType"
            value="DMA"
            label={
              <EuiText size="s">
                <strong>Targeting by DMA/Metro Area</strong> (one or more DMAs)
              </EuiText>
            }
            checked={locationTargeting.locationType === LocationTargetingType.DMA}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.DMA })
            }}
          />
          <EuiSpacer size="s" />
        </>
      )}
      {!preventTargeting.includes(LocationTargetingType.City) && (
        <>
          <EuiRadio
            id="city"
            name="locationType"
            value="City"
            label={
              <EuiText size="s">
                <strong>Targeting by City</strong> (one or more City names)
              </EuiText>
            }
            checked={locationTargeting.locationType === LocationTargetingType.City}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.City })
            }}
          />
          <EuiSpacer size="s" />
        </>
      )}
      {!preventTargeting.includes(LocationTargetingType.Zip) && (
        <>
          <EuiRadio
            id="zip"
            name="locationType"
            value="Zip"
            label={
              <EuiText size="s">
                <strong>Targeting by Zip Code</strong> (one or more Zip Codes)
              </EuiText>
            }
            checked={locationTargeting.locationType === LocationTargetingType.Zip}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Zip })
            }}
          />
          <EuiSpacer size="s" />
        </>
      )}
      {!preventTargeting.includes(LocationTargetingType.Map) && (
        <>
          <EuiRadio
            id="map"
            name="locationType"
            value="Map"
            label={
              <EuiText size="s">
                <strong>Target on Map</strong> (draw the areas you want to target on a map)
              </EuiText>
            }
            checked={locationTargeting.locationType === 'Map'}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationTargetingType.Map })
            }}
          />
          <EuiSpacer size="s" />
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.Region && (
        <>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr
              onOptionClick={region => {
                onLocationTargetingChange({
                  ...locationTargeting,
                  regions: [...(locationTargeting.regions ?? []), region]
                })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.regions?.map(r => ({ key: r.id.toString(), label: r.name ?? '' })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({
                  ...locationTargeting,
                  regions: locationTargeting.regions?.filter(r => r.id.toString() !== item.key)
                })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.DMA && (
        <>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={(dma: Dma) => onLocationTargetingChange({
              ...locationTargeting,
              dmas: [...(locationTargeting.dmas ?? []), dma]
            })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({
                  ...locationTargeting,
                  dmas: locationTargeting.dmas?.filter(r => r.id.toString() !== item.key)
                })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.City && (
        <>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={(city: City) => onLocationTargetingChange({
              ...locationTargeting,
              cities: [...(locationTargeting.cities ?? []), city]
            })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.cities?.map(r => ({
                key: r.id.toString(),
                label: `${r.name}, ${r.regionCode}`
              })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({
                  ...locationTargeting,
                  cities: locationTargeting.cities?.filter(r => r.id.toString() !== item.key)
                })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.Zip && (
        <>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr onOptionClick={(zip: PostalCode) => onLocationTargetingChange({
              ...locationTargeting,
              postalCodes: [...(locationTargeting.postalCodes ?? []), zip]
            })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({
                  ...locationTargeting,
                  postalCodes: locationTargeting.postalCodes?.filter(r => r.id.toString() !== item.key)
                })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === LocationTargetingType.Map && (
        <>
          <EuiCallOut size="s" iconType="mapMarker" color="success"
                      title="If you do not draw an area to target, the entire USA will be targeted by default." />
          <EuiSpacer size="s" />
          <TargetingMap
            geoCircles={locationTargeting.geoCircles ?? []}
            addCircle={(circle: GeoCircle) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoCircles: [...(locationTargeting.geoCircles ?? []), circle]
              })
            }}
            removeCircle={(id: string) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoCircles: locationTargeting.geoCircles?.filter(c => c.id !== id) ?? []
              })
            }}
            modifyCircle={(circle: GeoCircle) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoCircles: locationTargeting.geoCircles?.map(c => (c.id === circle.id ? circle : c))
              })
            }}
            geoRectangles={locationTargeting.geoRectangles ?? []}
            addRectangle={(rectangle: GeoRectangle) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoRectangles: [...(locationTargeting.geoRectangles ?? []), rectangle]
              })
            }}
            removeRectangle={(id: string) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoRectangles: locationTargeting.geoRectangles?.filter(r => r.id !== id) ?? []
              })
            }}
            modifyRectangle={(rectangle: GeoRectangle) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoRectangles: locationTargeting.geoRectangles?.map(r => (r.id === rectangle.id ? rectangle : r))
              })
            }}
            geoPolygons={locationTargeting.geoPolygons ?? []}
            addPolygon={(polygon: GeoPolygon) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoPolygons: [...(locationTargeting.geoPolygons ?? []), polygon]
              })
            }}
            removePolygon={(id: string) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoPolygons: locationTargeting.geoPolygons?.filter(p => p.id !== id) ?? []
              })
            }}
            modifyPolygon={(polygon: GeoPolygon) => {
              onLocationTargetingChange({
                ...locationTargeting,
                geoPolygons: locationTargeting.geoPolygons?.map(p => (p.id === polygon.id ? polygon : p)) ?? []
              })
            }}
          />
        </>
      )}
    </>
  )
}
