import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr'

type GetSignalRConnectionProps = {
  accessToken: string
  hubPath: string
  connection?: HubConnection
  setConnected: (connected: boolean) => void
}

export const getSignalRConnection = ({ accessToken, hubPath, connection, setConnected }: GetSignalRConnectionProps) => {
  if (!connection) {
    connection = new HubConnectionBuilder()
      .withUrl(`http://localhost:5031/${hubPath}`, {
        accessTokenFactory: () => accessToken ?? ''
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build()
    connection
      .start()
      .then(() => setConnected(true))
      .catch(err => console.error('Connection failed: ', err))
  }
  return connection
}
