import { ReactNode, useState } from 'react'

import { EuiGlobalToastList, EuiLink, EuiText } from '@elastic/eui'
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

import { usePlannerConnection } from '../utils/SignalR/Connections/usePlannerConnection'

export const NotificationHandler = ({ children }: { children: ReactNode }) => {
  usePlannerConnection({
    onSendTextMessage: ({ message }) => {
      if (message === 'Created') {
        setToasts([...toasts, clientPlanDoneToastProps])
      }
    }
  })

  const [toasts, setToasts] = useState<Toast[]>([])

  const removeToast = (removedToast: Toast) => {
    setToasts(toasts => toasts.filter(toast => toast.id !== removedToast.id))
  }

  const clientPlanDoneToastProps: Toast = {
    id: 'client-plan-done',
    title: 'Client plan has completed',
    text: (
      <EuiText>
        <p>You can view your client plan </p>
        <EuiLink href={'planner'}>here</EuiLink>
      </EuiText>
    )
  }

  return (
    <>
      {children}
      <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
    </>
  )
}
